import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import Badge from "../../components/_ui/Badge.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How to revamp wooden outdoor furniture`}</h1>
    <span className="text-metadata">Posted on 01/04</span>
    <Badge mdxType="Badge" />
    <img src="/img/outdoor-living/how-to-revamp-wooden-outdoor-furniture/Cuprinol_(10).jpg" alt="A set of outdoor folding chairs, painted an in an assortment of colours" />
    <p><strong parentName="p">{`A sunny May Bank Holiday?`}</strong>{` The gods must be looking kindly upon us in the south-east of England. But before you rush out to buy charcoal, shorts and the ingredients for Pina Colada, have a look at your outdoor furniture. Is it looking a little bit sorry for itself? Does it deserve some extra love? Heaven knows it normally takes a bashing from the elements, so perhaps it’s time to lavish some attention on your hard-working garden furniture before you fire up the barby.`}</p>
    <p>{`I’m not talking just about tables and chairs - the things that make outdoor socialising more comfortable and enticing - I’m also including the hard landscaping elements like fencing, sheds, trellises and, of course, pergolas. We need to get our ‘recessionista’ hats on and remember that Granny’s Make Do and Mend attitude may have been essential during the war but is no less valid today in these financially squeezed times.`}</p>
    <p>{`Untreated wood will weather over time, turning grey and generally looking shabby. It will also be more susceptible to stains, algae and mould. Yuck, right? No need to despair. It’s not difficult to give tired-looking garden furniture a ‘Make Do and Mend’ makeover. A bit of TLC and a lick of paint is often all your furniture needs to make it look shiny and new.`}</p>
    <img src="/img/outdoor-living/how-to-revamp-wooden-outdoor-furniture/Cuprinol_(8).jpg" alt="An outdoor seating area underneath a pergola, with an array of wicker garden seating and a barbeque" />
    <h2>{`Fences, trellises and pergolas`}</h2>
    <p>{`Both new and old weathered fences, trellises and pergolas will benefit from a weather-protecting product such as Cuprinol Ducksback (£17.99 for 5L from decoratenow.co.uk), which colours the wood as well as preserving it. It comes in a range of suitably earthy shades, and is wax-enriched which will penetrate into the wood to kill the fungal spores that cause rot. And if the weather forecast isn’t 100% reliable, well it’s touch dry within an hour of applying it, so it’s worth chancing it!`}</p>
    <p>{`If you've got a big area to tackle - a long fence or a shed for example, try a spray-on stain, such as Ronseal One Coat Sprayable Fencelife (£13.98 for 5l from B&Q), and use a Ronseal Pump Sprayer to get the job done in no time at all.`}</p>
    <h2>{`Outdoor wooden furniture`}</h2>
    <p>{`Hardwood garden furniture such as teak and iroko contains oils that naturally provide protection from the British weather. You can give your hardwood furniture an extra helping hand against the ravages of nature by using a specially formulated sealing product - choose one recommended by the furniture's manufacturer.`}</p>
    <p>{`Garden furniture made from softwood like pine or cedar can be pressure treated to protect it from decay and mould. An occasional wash with soapy water will keep it in good condition.
However, before you grab a paint brush or spray can, remember that preparation is all!`}</p>
    <p>{`Firstly, sand it down if it’s in a terrible state. If it’s just grubby, then brush away the dirt and dust with a soft-bristle brush.
Then, make up a solution of 60ml washing-up liquid and 3l warm water. Dip a lint-free cloth or sponge into your soapy mix, wring it out and wipe over all the surfaces. Rinse with clean water and dry completely before attempting to beautify it with paint or stain!`}</p>
    <p>{`Now for the fun bit...`}</p>
    <ul>
      <li parentName="ul">{`Apply your first coat of coloured wood stain or varnish and leave to dry`}</li>
      <li parentName="ul">{`Apply your second coat and leave for 24 hours before giving it a light sanding down with very fine sandpaper or wire wool.`}</li>
      <li parentName="ul">{`Stand back and admire your work. You are a DIY hero!`}</li>
    </ul>
    <p>{`Follow these simple guidelines, and you’ll be amazed at the transformation. For a small financial outlay and a bit of elbow grease, you’ll be able to enjoy your wooden outdoor furniture all summer and beyond. Now, we just need to keep our fingers crossed for that sunny May Bank Holiday…`}</p>
    <p>{`Picture credits:`}</p>
    <p>{`Picture caption 1: Cuprinol Garden Shades Coastal Mist and Sweet Pea. Priced at £23.99 2.5ltr (a vast array of other colours are available) from Decoratenow.co.uk`}</p>
    <p>{`Picture caption 2: Cuprinol Ducksback Forest Oak from Decoratenow.co.uk has been used to create a fabulous feature wall in this outdoor seating area. Priced at £17.99 5ltr.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      